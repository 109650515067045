import React from 'react'
import SVGCheck from '../assets/Check.svg'
import TextTransition, { presets } from "react-text-transition";
import BgVideo from "../assets/bg.mp4"

const TEXTS = [
  "à Perpignan !",
  "en ligne !",
  "à Saint-Amand-Montrond !",
  "à Bourges !",
  "à Bordeaux !",
  "à Paris !",
];

const Header = () => {
  const [index, setIndex] = React.useState(0);
  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      3000
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="relative" data-sal="fade"
      data-sal-duration="800" data-sal-easing="ease">
      <div className="group relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gris" />
        <div className="max-w-8xl mx-auto">
          <div className="relative shadow-xl rounded-xl sm:overflow-hidden">
            <div className="absolute inset-0 md:blur-sm">
              <video className="h-full w-full rounded-xl object-cover" src={BgVideo} type="video/mp4" autoPlay loop muted disablePictureInPicture>
              </video>
              <div className="absolute inset-0 rounded-xl bg-violetBright mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl break-words">
                <span data-sal="slide-up" data-sal-duration="1200" data-sal-easing="ease-in-out" className="block text-violetdiscret filter drop-shadow-xl">Découvrez dès maintenant,</span>
                <span data-sal="slide-up" data-sal-duration="1000" data-sal-delay="200" data-sal-easing="ease-in-out" className="block text-white md:text-5xl text-2xl drop-shadow-xl">votre agence web <TextTransition
                  style={{ whiteSpace: 'pre' }}
                  inline
                  text={TEXTS[index % TEXTS.length]}
                  springConfig={presets.default}
                /></span><span></span>
              </h1>
              <ul className="list-none mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                <li data-sal-delay="200" data-sal="zoom-out" data-sal-duration="1500" className="dark:text-white text-white"><i className="text-indigo-400 inline-block align-middle w-5"><SVGCheck /></i> Obtenez un devis gratuitement en 24h00 !</li>
                <li data-sal-delay="400" data-sal="zoom-out" data-sal-duration="1500" className="dark:text-white text-white"><i className="text-indigo-400 inline-block align-middle w-5"><SVGCheck /></i> Votre site Internet clé en mains</li>
                <li data-sal-delay="600" data-sal="zoom-out" data-sal-duration="1500" className="dark:text-white text-white"><i className="text-indigo-400 inline-block align-middle w-5"><SVGCheck /></i> Visibilité optimisée sur les moteurs de recherche</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header