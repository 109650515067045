import React from 'react';
import { navigate } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from 'prop-types';


const Bouton = ({
  className,
  modele,
  children,
  vers,
  href,
  handleClick,
  ...props
}) => {

  // Si le paramètre `vers` existe, on check le paramètre `modele` & on retourne un bouton
    if (modele) {
      if (modele === "li") {
        return (
          <AniLink cover bg="#6377E9" direction="up" duration={0.6}
          className={className} to={vers} onClick={() => navigate(vers)}>
            {children}
          </AniLink>
        );
      } else if (modele === "submit") {
        return(
          <button className={className} onClick={handleClick} type="submit">
            {children}
          </button>
        );
      } else {
        return (
          <AniLink cover bg="#6377E9" direction="up" duration={0.6}
            className='button button--link'
            to={vers}
          >
            {children}
          </AniLink>
        );
      }
    }


  if (href) {
    return (
      <a
        className='button button--link'
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <button
      aria-expanded={props['aria-expanded'] || null}
      className='button'
      {...props}
    >
      {children}
    </button>
  );
};


Bouton.propTypes = {
  handleClick: PropTypes.func
}


export default Bouton;