import React from "react"
import ThemeChanger from "./ThemeChanger"
import SVGLogo from '../assets/Logo.svg'
import Bouton from "./Bouton.js"
import '../styles/theme.css'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Fragment } from 'react'
import { Popover, Transition, Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { MenuIcon, XIcon, CogIcon, CodeIcon, PresentationChartLineIcon } from '@heroicons/react/solid'
import { Email } from "react-obfuscate-email";

const Complet = ({ children }) => {

	function classNames(...classes) {
		return classes.filter(Boolean).join(' ')
	}
	const solutions = [
		{
			name: 'Création de site Internet',
			description: 'Renforcez votre visibilité en ligne, confiez-nous la création de site web pour votre activité. (Site vitrine, site e-commerce, Click & Collect, etc.)',
			href: 'creation-site-internet/',
			icon: CodeIcon,
		},

		{
			name: 'Référencement naturel (SEO)',
			description: 'Améliorez votre positionnement et optimisez la visibilité de votre site Internet sur les moteurs de recherche (Google, Bing...) grâce à notre expertise SEO.',
			href: 'referencement-naturel-site-internet/',
			icon: PresentationChartLineIcon,
		},
		{ name: 'Maintenance de site Internet', description: "Assurez le bon fonctionnement de votre site Internet, confiez sa maintenance à notre agence web (ajout de contenu, sauvegardes, mise à jour de contenu, etc.).", href: 'maintenance-site-internet/', icon: CogIcon },
	]

	const buttonHeaderStyle = `
  py-3
  px-4
  bg-white
  text-violet
  transition 
  ease-in 
  duration-200 
  text-center 
  font-semibold 
  shadow-md 
  focus:outline-none 
  focus:ring-2 
  focus:ring-offset-2  
  rounded-lg
  `
	return (
		<Disclosure>
			{({ open }) => (
				<>
					<div id="layout" className="relative" className={open ? "overflow-hidden h-screen lg:overflow-auto" : ""}>
						{/* Navbar */}
						<div className="bg-w-primary">
						<div className="relative container mx-auto lg:px-6">
							<nav className="flex flex-wrap items-center content-between justify-between px-6 lg:px-0">
								{/* Logo SVG */}
								<div className="flex flex-no-shrink justify-self-start items-center mr-2 py-6 md:py-10 w-48 md:w-60 text-white">
									<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/"><SVGLogo /></AniLink>
								</div>
								{/* Liens du Menu */}
								<Disclosure.Button className="block lg:hidden">
									<input className="hidden justify-self-end" type="checkbox" />

									{open ? (
										<XIcon className="block h-6 w-6 text-white" aria-hidden="true" />
									) : (
										<MenuIcon className="block h-6 w-6 text-white" aria-hidden="true" />
									)}
								</Disclosure.Button>
								<ul className="menu transition-shadow mb-0 lg:mb-0 w-full lg:w-auto lg:space-x-5 text-center">
									<Transition className="flex flex-wrap lg:hidden justify-center items-center" open={open}
										enter="transition duration-500 ease-out"
										enterFrom="transition duration-500 ease-out transform scale-95 opacity-0"
										enterTo="transition duration-500 ease-out transform scale-100 opacity-100"
										leave="transition duration-75 ease-out"
										leaveFrom="transform scale-100 opacity-100"
										leaveTo="transform scale-95 opacity-0"
									>
										<Disclosure.Panel className="dark:bg-violetDarken h-screen w-screen">
											<li>
												<Popover className="relative">
													{({ open }) => (
														<>
															<Popover.Button
																className={classNames(
																	open ? 'text-white font-medium' : '',
																	'group items-center text-base anav inline-flex px-4 py-3 no-underline text-white'
																)}
															>
																Nos solutions
																<ChevronDownIcon
																	className={classNames(
																		open ? 'font-medium text-white' : 'text-white',
																		'ml-2 h-5 w-5 transition ease-in-out duration-150text-white'
																	)}
																	aria-hidden="true"
																/>
															</Popover.Button>
															{/* Dropdown Solutions */}
															<Transition
																show={open}
																as={Fragment}
																enter="transition ease-out duration-200"
																enterFrom="opacity-0 translate-y-1"
																enterTo="opacity-100 translate-y-0"
																leave="transition ease-in duration-150"
																leaveFrom="opacity-100 translate-y-0"
																leaveTo="opacity-0 translate-y-1"
															>
																<Popover.Panel
																	static
																	className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl text-left"
																>
																	<div className="rounded-lg shadow-lg overflow-hidden">
																		<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
																			{solutions.map((item) => (
																				<AniLink
																					cover bg="#6377E9" direction="up" duration={0.6}
																					key={item.name}
																					to={"/" + item.href}
																					className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
																				>
																					<div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-w-primary text-white sm:h-12 sm:w-12">
																						<item.icon className="h-6 w-6" aria-hidden="true" />
																					</div>
																					<div className="ml-4">
																						<p className="text-base font-medium text-gray-900">{item.name}</p>
																						<p className="mt-1 text-sm text-gray-500">{item.description}</p>
																					</div>
																				</AniLink>
																			))}
																		</div>
																	</div>
																</Popover.Panel>
															</Transition>
														</>
													)}
												</Popover>
											</li>
											<li className="mb-5 lg:mb-0">
												<AniLink cover bg="#6377E9" direction="up" duration={0.6} activeClassName="font-medium active text-white" className="anav block md:inline-block px-4 py-3 no-underline text-white" to="/nos-realisations/">Nos réalisations</AniLink>
											</li>
											{/*  Bouton du Menu */}
											<Bouton className={buttonHeaderStyle} vers="/contact/" modele="li">Contactez-nous</Bouton>
										</Disclosure.Panel>
									</Transition>
								</ul>
								<ul className="menu transition-shadow mb-5 lg:mb-0 w-full lg:w-auto lg:space-x-5 text-center hidden lg:flex">
									<li>
										<Popover className="relative">
											{({ open }) => (
												<>
													<Popover.Button
														className={classNames(
															open ? 'text-white font-medium' : '',
															'group items-center text-base anav inline-flex px-4 py-3 no-underline text-white'
														)}
													>
														Nos solutions
														<ChevronDownIcon
															className={classNames(
																open ? 'font-medium text-white' : 'text-white',
																'ml-2 h-5 w-5 transition ease-in-out duration-150 text-white'
															)}
															aria-hidden="true"
														/>
													</Popover.Button>
													{/* Dropdown Solutions */}
													<Transition
														show={open}
														as={Fragment}
														enter="transition ease-out duration-200"
														enterFrom="opacity-0 translate-y-1"
														enterTo="opacity-100 translate-y-0"
														leave="transition ease-in duration-150"
														leaveFrom="opacity-100 translate-y-0"
														leaveTo="opacity-0 translate-y-1"
													>
														<Popover.Panel
															static
															className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen sm:px-0 lg:max-w-lg text-left"
														>
															<div className="rounded-lg shadow-lg overflow-hidden">
																<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
																	{solutions.map((item) => (
																		<AniLink
																			cover bg="#6377E9" direction="up" duration={0.6}
																			key={item.name}
																			to={"/" + item.href}
																			className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
																		>
																			<div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-w-primary text-white sm:h-12 sm:w-12">
																				<item.icon className="h-6 w-6" aria-hidden="true" />
																			</div>
																			<div className="ml-4">
																				<p className="text-base font-medium text-gray-900">{item.name}</p>
																				<p className="mt-1 text-sm text-gray-500">{item.description}</p>
																			</div>
																		</AniLink>
																	))}
																</div>
															</div>
														</Popover.Panel>
													</Transition>
												</>
											)}
										</Popover>
									</li>
									<li className="mb-5 lg:mb-0">
										<AniLink cover bg="#6377E9" direction="up" duration={0.6} activeClassName="font-medium active text-violet" className="anav block md:inline-block px-4 py-3 no-underline text-white" to="/nos-realisations/">Nos réalisations</AniLink>
									</li>
									{/*  Bouton du Menu */}
									<Bouton className={buttonHeaderStyle} vers="/contact/" modele="li">Contactez-nous</Bouton>
								</ul>
							</nav>
						</div>
						</div>

						<main className="relative">
							{children}
						</main>

						<footer className="py-6 px-6 dark:bg-d-secondary bg-w-secondary relative text-gray-700 dark:text-white">
							<div className="container px-6 lg:px-0 mx-auto">
								<div className="grid grid-cols-12 gap-0 lg:gap-10 place-items-center items-stretch">
									<div className="col-span-full pb-6 lg:place-self-start text-center lg:text-left lg:pb-0 lg:col-span-3">
										<div className="w-48 mx-auto lg:mx-0 text-violet dark:text-white">
											<SVGLogo />
										</div>
										<p className="font-medium mt-3"><span className="font-bold">Téléphone:</span> 06 18 96 16 80</p>
										<p className="font-medium"><span className="font-bold">E-mail:</span> <Email email="contact@koomet.fr">📧 Cliquez ici !</Email></p>
										<Bouton className="inline-block mt-3 py-3 px-4 bg-w-primary text-white transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg" vers="/contact/" modele="li">Contactez-nous</Bouton>
									</div>
									<div className="col-span-full mb-5 lg:mb-0 text-center lg:text-left lg:col-span-3">
										<p className="pb-2 text-lg font-medium text-gray-800 dark:text-white">Notre expertise</p>
										<ul>
											<li>
												<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/creation-site-internet/" className="hover:text-violet transition ease-in-out duration-200">Création de site Internet</AniLink>
											</li>
											<li>
												<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/referencement-naturel-site-internet/" className="hover:text-violet transition ease-in-out duration-200">Référencement naturel (SEO)</AniLink>
											</li>
											<li>
												<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/maintenance-site-internet/" className="hover:text-violet transition ease-in-out duration-200">Maintenance de site Internet</AniLink>
											</li>
										</ul>
									</div>
									<div className="col-span-full mb-5 lg:mb-0 text-center lg:text-left lg:col-span-2">
										<p className="pb-2 text-lg font-medium text-gray-800 dark:text-white">En savoir plus</p>
										<ul>
											<li>
												<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/nos-realisations/" className="hover:text-violet transition ease-in-out duration-200">Nos réalisations</AniLink>
											</li>
											<li>
												<button className="pointer-events-none text-gray-500 dark:text-gray-400">Blog <sup className="text-xs tracking-widest capitalize">bientôt</sup></button>
											</li>
										</ul>
									</div>
									<div className="col-span-full text-center lg:text-left lg:col-span-4">
										<p className="pb-2 text-lg font-medium text-gray-800 dark:text-white">Nos agences en ligne</p>
										<ul>
											<li>
												<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/agence/saint-amand-montrond/" className="hover:text-violet transition ease-in-out duration-200">Agence web Saint-Amand-Montrond</AniLink>
											</li>
											<li>
												<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/agence/bourges/" className="hover:text-violet transition ease-in-out duration-200">Agence web Bourges</AniLink>
											</li>
											<li>
												<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/agence/paris/" className="hover:text-violet transition ease-in-out duration-200">Agence web Paris</AniLink>
											</li>
											<li>
												<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/agence/bordeaux/" className="hover:text-violet transition ease-in-out duration-200">Agence web Bordeaux</AniLink>
											</li>
										</ul>
									</div>
								</div>
								<div className="grid justify-center pt-6 lg:justify-between">
									<div className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
										<span>© 2022 Koomet.</span>
										<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/mentions-legales/">
											<span>Mentions légales.</span>
										</AniLink>
										<AniLink cover bg="#6377E9" direction="up" duration={0.6} to="/politique-de-confidentialite/">
											<span>Politique de confidentialité.</span>
										</AniLink>
									</div>
									<div className="flex justify-center pt-4 space-x-6 lg:pt-0 lg:col-end-13">
										<ThemeChanger modele="mobile" />
										<a href="https://www.facebook.com/koometagency" target="_blank" rel="noreferrer" title="Facebook" className="flex items-center justify-center self-center w-7 h-7 rounded-full hover:text-violet transition ease-in-out duration-200">
											<span className="sr-only">Facebook Agence web Koomet</span>
											<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path
												fillRule="evenodd"
												d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
												clipRule="evenodd"
											/>
											</svg>
										</a>
										<a href="https://www.linkedin.com/company/koomet" target="_blank" rel="noreferrer" title="Twitter" className="flex items-center self-center justify-center w-6 h-6 rounded-full hover:text-violet transition ease-in-out duration-200">
											<span className="sr-only">LinkedIn Agence web Koomet</span>
											
<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"/></svg>
											
										</a>
									</div>
								</div>
							</div>
						</footer>
					</div>
				
				</>
			)}
		</Disclosure>
	)
}

export default Complet